import * as React from "react";
import { CoinDetailsMapped } from "../../../../../types/api-results/coin-details";
import { CurrencySymbol } from "../../../../../types/currency";
import { SiteContext } from "../../../../site-context/site-context";
import { formatCurrency, formatSupply } from "../../../../utils/format-number";
import SectionCard from "../../../ui-library/cards/section-card/section-card";
import Sparkline from "../../../ui-library/charts/sparkline";
import Column from "../../../ui-library/column-system/column";
import Row from "../../../ui-library/column-system/row";
import { grid } from "../../../ui-library/design-tokens/grid";
import Image from "../../../ui-library/image/image";
import MarginBox from "../../../ui-library/margin-box/margin-box";
import Separator from "../../../ui-library/separator/separator";
import Heading from "../../../ui-library/text/heading";
import InfinitySymbol from "../../../../images/infinity-symbol.svg";
import TrendText from "../../../ui-library/text/trend-text";
import CoinLinks from "../coin-links/coin-links";
import styles from "./coin-overview.styles";

interface CoinOverviewProps {
  data?: CoinDetailsMapped;
  loading: boolean;
}

const CoinOverview: React.FC<CoinOverviewProps> = ({ data, loading }) => {
  const {
    isMobile,
    currency: [currency],
  } = React.useContext(SiteContext);

  if (!data) {
    return null;
  }

  const { name, image, marketCapRank, symbol, marketData = {}, isDefi } = data;

  const secondaryCurrencySelection = isDefi
    ? CurrencySymbol.ETH
    : CurrencySymbol.BTC;
  const secondaryCurrency =
    currency === secondaryCurrencySelection
      ? CurrencySymbol.USD
      : secondaryCurrencySelection;

  const imageUrl = image?.large || image?.thumb || image?.small;

  const {
    currentPrice,
    low24h,
    high24h,
    priceChangePercentage24hInCurrency,
    priceChangePercentage7d,
    maxSupply,
    circulatingSupply,
    marketCap,
    totalVolume,
    sparkline7d,
  } = marketData;

  return (
    <SectionCard css={styles.root}>
      {marketCapRank && <div css={styles.rankBadge}>RANK {marketCapRank}</div>}

      <div css={styles.header}>
        <div css={styles.headerLeft}>
          <div css={styles.imageContainer}>
            <Image src={imageUrl} css={styles.image} alt={name || "logo"} />
          </div>

          <div>
            <Heading component="h1" size="large" margin={{ bottom: grid(0.5) }}>
              {name}
            </Heading>
            <div css={styles.label}>{symbol?.toUpperCase()}</div>
          </div>
        </div>

        {isMobile && <Separator margin={{ bottom: grid(2) }} />}

        <div css={styles.headerRight}>
          <Heading size="large" margin={{ bottom: grid(0.5) }}>
            {formatCurrency({ value: currentPrice?.[currency], currency })}
          </Heading>
          <div css={styles.label}>
            {formatCurrency({
              value: currentPrice?.[secondaryCurrency],
              currency: secondaryCurrency,
            })}
          </div>
        </div>
      </div>

      <Separator margin={{ bottom: grid(2) }} />

      <Row>
        <Column spanLg={3} spanMd={3} spanSm={6} spanXs={6}>
          <div>
            <div css={styles.label}>Last 7 days</div>
            <Sparkline
              price={sparkline7d?.price}
              changePercent={priceChangePercentage7d}
            />
          </div>
        </Column>
        <Column spanLg={3} spanMd={3} spanSm={6} spanXs={6}>
          <div>
            <div css={styles.label}>24h Change</div>
            <TrendText
              css={styles.value}
              value={priceChangePercentage24hInCurrency?.[currency]}
              isColored={true}
              arrowSpacing={6}
            />
          </div>
        </Column>
        <Column spanLg={3} spanMd={3} spanSm={6} spanXs={6}>
          <div>
            <div css={styles.label}>24h Low</div>
            <div css={styles.value}>
              {formatCurrency({ value: low24h?.[currency], currency })}
            </div>
          </div>
        </Column>
        <Column spanLg={3} spanMd={3} spanSm={6} spanXs={6}>
          <div>
            <div css={styles.label}>24h High</div>
            <div css={styles.value}>
              {formatCurrency({ value: high24h?.[currency], currency })}
            </div>
          </div>
        </Column>
        <Column spanLg={3} spanMd={3} spanSm={6} spanXs={6}>
          <div>
            <div css={styles.label}>Max Supply</div>
            <div css={styles.value}>
              {formatSupply({ value: maxSupply }) || (
                <Image src={InfinitySymbol} alt="Infinite" />
              )}
            </div>
          </div>
        </Column>
        <Column spanLg={3} spanMd={3} spanSm={6} spanXs={6}>
          <div>
            <div css={styles.label}>Circulating Supply</div>
            <div css={styles.value}>
              {formatSupply({ value: circulatingSupply })}
            </div>
          </div>
        </Column>
        <Column spanLg={3} spanMd={3} spanSm={6} spanXs={6}>
          <div>
            <div css={styles.label}>Market Cap</div>
            <div css={styles.value}>
              {formatCurrency({ value: marketCap?.[currency], currency })}
            </div>
          </div>
        </Column>
        <Column spanLg={3} spanMd={3} spanSm={6} spanXs={6}>
          <div>
            <div css={styles.label}>24h Volume</div>
            <div css={styles.value}>
              {formatCurrency({ value: totalVolume?.[currency], currency })}
            </div>
          </div>
        </Column>
      </Row>

      <MarginBox margin={{ top: grid(2) }}>
        <CoinLinks data={data} loading={loading} />
      </MarginBox>
    </SectionCard>
  );
};

export default CoinOverview;
