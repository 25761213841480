import { CSSObject } from "@emotion/react";
import { Colors } from "../../../../../ui-library/design-tokens/colors";
import { Fonts } from "../../../../../ui-library/design-tokens/fonts";
import { grid } from "../../../../../ui-library/design-tokens/grid";
import { Device } from "../../../../../ui-library/design-tokens/media-queries";

const label: CSSObject = {
  fontSize: 12,
  fontWeight: Fonts.Weight.SemiBold,
  color: Colors.MidGrey,
  textAlign: "center",
  marginBottom: grid(0.5),

  [Device.DesktopTablet]: {
    fontSize: 14,
    marginBottom: grid(1),
  },
};

const atInfo: CSSObject = {
  [Device.DesktopTablet]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  [Device.Mobile]: {
    textAlign: "center",
  },
};

const atDetails: CSSObject = {
  display: "flex",
  alignItems: "center",

  [Device.Mobile]: {
    justifyContent: "center",
  },

  [Device.MobilePortXs]: {
    display: "block",
  },
};

const value: CSSObject = {
  fontSize: 14,
  fontWeight: Fonts.Weight.Bold,

  [Device.DesktopTablet]: {
    fontSize: 18,
  },

  [Device.Mobile]: {
    marginBottom: grid(0.5),
  },
};

const percent: CSSObject = {
  fontSize: 12,
  fontWeight: Fonts.Weight.SemiBold,

  [Device.DesktopTablet]: {
    fontSize: 14,
    marginLeft: grid(1),
  },
};

const date: CSSObject = {
  fontSize: 12,
  fontWeight: Fonts.Weight.SemiBold,
  marginLeft: grid(1),

  [Device.DesktopTablet]: {
    fontSize: 14,
  },

  [Device.MobilePortXs]: {
    marginTop: grid(0.5),
    marginLeft: 0,
  },
};

const styles = {
  label,
  atInfo,
  atDetails,
  value,
  percent,
  date,
};

export default styles;
