import { getCoinTickersQueryUrl } from "../../../data/coingecko-endpoints";
import { CoinTickersResult } from "../../../types/api-results/coin-tickers";
import { FETCH_INTERVAL } from "../../constants/data-fetch";
import { mapCoinTickers } from "../../utils/data-mappings/map-coin-tickers";
import { useAxios } from "./use-axios";

interface UseCoinTickersInput {
  id: string;
}

export const useCoinTickers = ({ id }: UseCoinTickersInput) => {
  const url = getCoinTickersQueryUrl({ id });

  const { data, error, loading, timestamp } = useAxios<{
    tickers?: CoinTickersResult[];
  }>(url, {
    refetchInterval: FETCH_INTERVAL,
  });

  return {
    data: mapCoinTickers({ data: data?.tickers }),
    error,
    loading,
    timestamp,
  };
};
