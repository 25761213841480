import { getCoinMarketDataQueryUrl } from "../../../data/coingecko-endpoints";
import { CoinMarketDataResult } from "../../../types/api-results/coin-market-data";
import { MarketDays } from "../../../types/coin-market-data";
import { FETCH_INTERVAL } from "../../constants/data-fetch";
import { mapCoinMarketData } from "../../utils/data-mappings/map-coin-market-data";
import { useAxios } from "./use-axios";

interface UseCoinMarketDataInput {
  id: string;
  days: MarketDays;
}

export const useCoinMarketData = ({ id, days }: UseCoinMarketDataInput) => {
  const url = getCoinMarketDataQueryUrl({ id, days });

  const { data, error, loading, timestamp } = useAxios<CoinMarketDataResult>(
    url,
    {
      refetchInterval: FETCH_INTERVAL,
    }
  );

  return {
    data: mapCoinMarketData(data),
    error,
    loading,
    timestamp,
  };
};
