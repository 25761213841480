import { getCoinDetailQueryUrl } from "../../../data/coingecko-endpoints";
import { CoinListItem } from "../../../graphql-types";
import { CoinDetailsResult } from "../../../types/api-results/coin-details";
import { FETCH_INTERVAL } from "../../constants/data-fetch";
import { mapCoinDetails } from "../../utils/data-mappings/map-coin-details";
import { useAxios } from "./use-axios";

interface UseCoinDetailsInput {
  id: string;
  staticData?: CoinListItem;
}

export const useCoinDetails = ({ id, staticData }: UseCoinDetailsInput) => {
  const url = getCoinDetailQueryUrl({ id });

  const { data, error, loading, timestamp } = useAxios<CoinDetailsResult>(url, {
    refetchInterval: FETCH_INTERVAL,
  });

  return {
    data: mapCoinDetails({ data, fallbackData: staticData }),
    error,
    loading,
    timestamp,
  };
};
