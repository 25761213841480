import * as React from "react";
import {
  CoinListItem,
  CoinomicsQuery_CoinCategory,
} from "../../../../graphql-types";
import { useCoinDetails } from "../../../hooks/query-hooks/use-coin-details";
import BackAndSearch from "../../shared/back-and-search/back-and-search";
import Container from "../../ui-library/container/container";
import CoinCategories from "./coin-categories/coin-categories";
import CoinDescription from "./coin-description/coin-description";
import CoinOverview from "./coin-overview/coin-overview";
import CoinStatsNMarket from "./coin-stats-n-market/coin-stats-n-market";

interface CoinProps {
  id: string;
  staticData?: CoinListItem;
  categories?: CoinomicsQuery_CoinCategory[] | null;
}

const Coin: React.FC<CoinProps> = ({ id, staticData, categories }) => {
  const { data, loading } = useCoinDetails({ id, staticData });

  return (
    <>
      <BackAndSearch />

      <Container fullWidthOnMobile={true}>
        <CoinOverview data={data} loading={loading} />
        <CoinStatsNMarket id={id} data={data} loading={loading} />
        {categories && categories.length > 0 && (
          <CoinCategories categories={categories} />
        )}
        <CoinDescription data={data} />
      </Container>
    </>
  );
};

export default Coin;
