import * as React from "react";
import { Portal } from "react-portal";
import AnimateHeight from "react-animate-height";
import { grid } from "../../design-tokens/grid";
import { Card } from "../card";
import { Device } from "../../design-tokens/media-queries";
import { Fonts } from "../../design-tokens/fonts";

interface ToastCardProps {
  isTriggered?: boolean;
}

const ToastCard: React.FC<ToastCardProps> = ({ isTriggered, children }) => {
  const [isVisible, setVisible] = React.useState<boolean>(false);

  React.useEffect(() => {
    let timer: NodeJS.Timeout;

    if (isTriggered) {
      setVisible(true);
    }

    if (isVisible) {
      const action = () => {
        setVisible(false);
      };
      timer = setTimeout(action, 5000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [isTriggered, isVisible]);

  return (
    <Portal>
      <div
        css={{
          position: "fixed",
          bottom: grid(2),
          right: grid(2),
          zIndex: 500,
          [Device.DesktopTablet]: { maxWidth: grid(35) },
          [Device.Mobile]: { left: grid(2) },
        }}
      >
        <AnimateHeight duration={500} height={isVisible ? "auto" : 0}>
          <Card
            pop={true}
            css={{
              fontSize: 14,
              fontWeight: Fonts.Weight.SemiBold,
              padding: grid(2),
            }}
          >
            {children}
          </Card>
        </AnimateHeight>
      </div>
    </Portal>
  );
};

export default ToastCard;

export const useToast = () => {
  const [isTriggered, setTriggered] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (isTriggered) {
      setTriggered(false);
    }
  }, [isTriggered]);

  const trigger = () => setTriggered(true);

  return { trigger, isTriggered };
};
