import * as React from "react";
import { CoinDetailsMapped } from "../../../../../types/api-results/coin-details";
import { useCoinTickers } from "../../../../hooks/query-hooks/use-coin-tickers";
import { EventCategory } from "../../../../utils/analytics";
import SectionCard from "../../../ui-library/cards/section-card/section-card";
import { grid } from "../../../ui-library/design-tokens/grid";
import { Device } from "../../../ui-library/design-tokens/media-queries";
import ItemPicker from "../../../ui-library/item-picker/item-picker";
import MarginBox from "../../../ui-library/margin-box/margin-box";
import CoinStats from "./coin-stats/coin-stats";
import CoinTickers from "./coin-tickers/coin-tickers";

const tabs = ["Overview", "Markets"];

interface CoinStatsNMarketProps {
  id: string;
  data?: CoinDetailsMapped;
  loading: boolean;
}

const CoinStatsNMarket: React.FC<CoinStatsNMarketProps> = ({
  id,
  data,
  loading,
}) => {
  const {
    data: coinTickersData,
    loading: coinTickersLoading,
  } = useCoinTickers({ id });

  const [activeTab, setActiveTab] = React.useState<string>(tabs[0]);

  if (!data) {
    return null;
  }

  const topTargetSymbol = coinTickersData?.[0].target;
  const topExchange = coinTickersData?.[0].market.identifier;

  return (
    <SectionCard
      css={{
        paddingTop: grid(3),
        [Device.DesktopTablet]: {
          paddingTop: grid(4),
        },
      }}
    >
      <MarginBox
        margin={{ desktop: { bottom: grid(4) }, mobile: { bottom: grid(3) } }}
      >
        <ItemPicker
          itemPickerName="coinStatsAndMarket"
          activeItemValue={activeTab}
          items={tabs.map((tab) => ({ label: tab, value: tab }))}
          isUniWidth={true}
          onItemClick={setActiveTab}
          eventCategory={EventCategory.CoinPage}
        />
      </MarginBox>

      {activeTab === tabs[0] && (
        <CoinStats
          data={data}
          loading={loading}
          topTargetSymbol={topTargetSymbol}
          topExchange={topExchange}
        />
      )}

      {activeTab === tabs[1] && (
        <CoinTickers
          data={data}
          coinTickersData={coinTickersData}
          loading={coinTickersLoading}
        />
      )}
    </SectionCard>
  );
};

export default CoinStatsNMarket;
