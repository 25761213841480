import { CSSObject } from "@emotion/react";
import { grid } from "../../../../../ui-library/design-tokens/grid";
import { Device } from "../../../../../ui-library/design-tokens/media-queries";

const tabsContainer: CSSObject = {
  marginBottom: grid(3),

  [Device.Desktop]: {
    display: "flex",
    justifyContent: "space-between",
  },
};

const styles = {
  tabsContainer,
};

export default styles;
