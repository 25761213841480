import * as React from "react";
import formatDate from "dateformat";
import { CoinDetailsMarketDataMapped } from "../../../../../../../types/api-results/coin-details";
import { SiteContext } from "../../../../../../site-context/site-context";
import Row from "../../../../../ui-library/column-system/row";
import Column from "../../../../../ui-library/column-system/column";
import { formatCurrency } from "../../../../../../utils/format-number";
import TrendText from "../../../../../ui-library/text/trend-text";
import styles from "./ath-atl.styles";

interface AthAtlProps {
  marketData?: CoinDetailsMarketDataMapped;
}

const AthAtl: React.FC<AthAtlProps> = ({ marketData = {} }) => {
  const {
    currency: [currency],
  } = React.useContext(SiteContext);

  const athDate = marketData.athDate?.[currency];
  const atlDate = marketData.atlDate?.[currency];

  const displayValues = {
    ath: marketData.ath?.[currency],
    athChangePercentage: marketData.athChangePercentage?.[currency],
    athDate: athDate ? formatDate(new Date(athDate), "mmm dd yyyy") : undefined,
    atl: marketData.atl?.[currency],
    atlChangePercentage: marketData.atlChangePercentage?.[currency],
    atlDate: atlDate ? formatDate(new Date(atlDate), "mmm dd yyyy") : undefined,
    priceChangePercentage1hInCurrency:
      marketData.priceChangePercentage1hInCurrency?.[currency],
    priceChangePercentage24hInCurrency:
      marketData.priceChangePercentage24hInCurrency?.[currency],
    priceChangePercentage7dInCurrency:
      marketData.priceChangePercentage7dInCurrency?.[currency],
    priceChangePercentage14dInCurrency:
      marketData.priceChangePercentage14dInCurrency?.[currency],
    priceChangePercentage30dInCurrency:
      marketData.priceChangePercentage30dInCurrency?.[currency],
    priceChangePercentage1yInCurrency:
      marketData.priceChangePercentage1yInCurrency?.[currency],
  };

  return (
    <Row>
      <Column spanLg={6} spanMd={6} spanSm={6} spanXs={6}>
        <div>
          <div css={styles.label}>All-Time High</div>
          <div css={styles.atInfo}>
            <div css={styles.value}>
              {formatCurrency({ value: displayValues.ath, currency }) || "-"}
            </div>
            <div css={styles.atDetails}>
              <TrendText
                css={styles.percent}
                value={displayValues.athChangePercentage}
                isColored={true}
                arrowSpacing={3}
                arrowSize={{ width: 7, height: 6 }}
              />
              <div css={styles.date}>{displayValues.athDate}</div>
            </div>
          </div>
        </div>
      </Column>
      <Column spanLg={6} spanMd={6} spanSm={6} spanXs={6}>
        <div>
          <div css={styles.label}>All-Time Low</div>
          <div css={styles.atInfo}>
            <div css={styles.value}>
              {formatCurrency({ value: displayValues.atl, currency }) || "-"}
            </div>
            <div css={styles.atDetails}>
              <TrendText
                css={styles.percent}
                value={displayValues.atlChangePercentage}
                isColored={true}
                arrowSpacing={3}
                arrowSize={{ width: 7, height: 6 }}
              />
              <div css={styles.date}>{displayValues.atlDate}</div>
            </div>
          </div>
        </div>
      </Column>
    </Row>
  );
};

export default AthAtl;
