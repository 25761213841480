import { CoinDetailsMapped } from "../../../../../types/api-results/coin-details";

export interface CoinLink {
  name: string;
  url: string;
}

const isNotEmpty = (url?: string | number | null) => {
  if (typeof url === "string") {
    return url?.trim() !== "";
  }
  return !!url;
};

const extractDomain = (url?: string | null) => {
  if (!url || !isNotEmpty(url)) {
    return null;
  }
  return url.split("/")?.[2];
};

const mapUrls = (urls?: string[]) =>
  (urls
    ?.map((url) =>
      isNotEmpty(url) ? { name: extractDomain(url), url } : undefined
    )
    .filter(Boolean) as CoinLink[] | undefined) || [];

const extractGithubIds = (urls?: string[]) => {
  const getUserId = (url?: string) => {
    const noProtocal = url?.split("//")?.[1];
    const urlParts = noProtocal?.split("/");

    if (urlParts && urlParts[0].includes("github")) {
      return urlParts[1];
    }
    return undefined;
  };

  return urls?.reduce((ids: string[], url) => {
    const id = getUserId(url);

    if (id && !ids.includes(id)) {
      ids.push(id);
    }
    return ids;
  }, []);
};

export const formatCoinLinks = (data: CoinDetailsMapped) => {
  if (!data.links) {
    return null;
  }

  const {
    homepage,
    blockchainSite,
    officialForumUrl,
    chatUrl,
    announcementUrl,
    twitterScreenName,
    facebookUsername,
    telegramChannelIdentifier,
    bitcointalkThreadIdentifier,
    subredditUrl,
    reposUrl,
  } = data.links;

  const formattedUrls = {
    twitter: isNotEmpty(twitterScreenName)
      ? {
          name: "Twitter",
          url: `https://twitter.com/${twitterScreenName}`,
        }
      : undefined,
    telegram: isNotEmpty(telegramChannelIdentifier)
      ? {
          name: "Telegram",
          url: `https://t.me/${telegramChannelIdentifier}`,
        }
      : undefined,
    reddit: isNotEmpty(subredditUrl)
      ? {
          name: "Reddit",
          url: subredditUrl,
        }
      : undefined,
    officialForum: mapUrls(officialForumUrl),
    bitcointalk: isNotEmpty(bitcointalkThreadIdentifier)
      ? {
          name: "Bitcointalk",
          url: `https://bitcointalk.org/index.php?topic=${bitcointalkThreadIdentifier}`,
        }
      : undefined,
    facebook: isNotEmpty(facebookUsername)
      ? {
          name: "Facebook",
          url: `https://www.facebook.com/${facebookUsername}`,
        }
      : undefined,
    chats: mapUrls(chatUrl),
    websites: mapUrls(homepage),
    announcement: mapUrls(announcementUrl),
    blockchainSite: mapUrls(blockchainSite),
    sourceCode:
      (extractGithubIds(reposUrl?.github)?.map((id) => ({
        name: "Github",
        url: `https://github.com/${id}`,
      })) as CoinLink[] | undefined) || [],
  };

  return formattedUrls;
};

export const flattenCoinLinks = (data: CoinDetailsMapped) => {
  const formattedUrls = formatCoinLinks(data);

  if (!formattedUrls) {
    return null;
  }

  const topLinks = {
    twitter: formattedUrls.twitter,
    telegram: formattedUrls.telegram,
    topExplorer: formattedUrls.blockchainSite[0],
    topWebsite: formattedUrls.websites[0],
  };

  const otherLinks = [
    formattedUrls.reddit,
    ...formattedUrls.officialForum,
    formattedUrls.bitcointalk,
    formattedUrls.facebook,
    ...formattedUrls.chats,
    ...formattedUrls.websites.slice(1),
    ...formattedUrls.announcement,
    ...formattedUrls.blockchainSite.slice(1),
    ...formattedUrls.sourceCode,
  ].filter(Boolean) as CoinLink[];

  return {
    topLinks,
    otherLinks,
  };
};
