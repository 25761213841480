import * as React from "react";
import { CoinDetailsMarketDataMapped } from "../../../../../../../types/api-results/coin-details";
import { SiteContext } from "../../../../../../site-context/site-context";
import TrendText from "../../../../../ui-library/text/trend-text";
import styles from "./price-change-of-time-frame.styles";

interface PercentCellProps {
  value?: number | null;
  isMobile: boolean;
}
const PercentCell: React.FC<PercentCellProps> = ({ value, isMobile }) => (
  <TrendText
    css={styles.percent}
    value={value}
    isColored={true}
    arrowSpacing={isMobile ? 3 : 5}
    arrowSize={isMobile ? { width: 7, height: 6 } : undefined}
  />
);

interface PriceChangeOfTimeFrameProps {
  marketData?: CoinDetailsMarketDataMapped;
}

const PriceChangeOfTimeFrame: React.FC<PriceChangeOfTimeFrameProps> = ({
  marketData = {},
}) => {
  const {
    currency: [currency],
    isMobile,
  } = React.useContext(SiteContext);

  const priceChange = {
    p1h: marketData.priceChangePercentage1hInCurrency?.[currency],
    p24h: marketData.priceChangePercentage24hInCurrency?.[currency],
    p7d: marketData.priceChangePercentage7dInCurrency?.[currency],
    p14d: marketData.priceChangePercentage14dInCurrency?.[currency],
    p30d: marketData.priceChangePercentage30dInCurrency?.[currency],
    p1y: marketData.priceChangePercentage1yInCurrency?.[currency],
  };

  return (
    <div>
      <div css={[styles.row, styles.header]}>
        {!isMobile && <div>1h</div>}
        <div>24h</div>
        <div>7d</div>
        <div>14d</div>
        <div>30d</div>
        <div>1y</div>
      </div>

      <div css={[styles.row, styles.body]}>
        {!isMobile && (
          <PercentCell value={priceChange.p1h} isMobile={isMobile} />
        )}
        <PercentCell value={priceChange.p24h} isMobile={isMobile} />
        <PercentCell value={priceChange.p7d} isMobile={isMobile} />
        <PercentCell value={priceChange.p14d} isMobile={isMobile} />
        <PercentCell value={priceChange.p30d} isMobile={isMobile} />
        <PercentCell value={priceChange.p1y} isMobile={isMobile} />
      </div>
    </div>
  );
};

export default PriceChangeOfTimeFrame;
