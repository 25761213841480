import * as React from "react";
import { CoinDetailsMapped } from "../../../../../../types/api-results/coin-details";
import { CoinTickersMapped } from "../../../../../../types/api-results/coin-tickers";
import { SiteContext } from "../../../../../site-context/site-context";
import { EventCategory } from "../../../../../utils/analytics";
import CoinTickersTable from "../../../../shared/coin-tickers-table/coin-tickers-table";
import CoinTickersTableSkeleton from "../../../../shared/coin-tickers-table/coin-tickers-table-skeleton";

interface CoinTickersProps {
  data: CoinDetailsMapped;
  coinTickersData?: CoinTickersMapped[];
  loading: boolean;
}

const CoinTickers: React.FC<CoinTickersProps> = ({
  data: coinData,
  coinTickersData,
  loading,
}) => {
  const {
    currency: [currency],
  } = React.useContext(SiteContext);

  const totalVolume = coinData.marketData?.totalVolume?.[currency];

  if (loading) {
    return <CoinTickersTableSkeleton />;
  }

  if (!coinTickersData || !totalVolume) {
    return null;
  }

  return (
    <CoinTickersTable
      data={coinTickersData}
      totalVolume={totalVolume}
      tableFor="coin"
      eventCategory={EventCategory.CoinPage}
    />
  );
};

export default CoinTickers;
