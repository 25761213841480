import { CSSObject } from "@emotion/react";
import { grid } from "../../../ui-library/design-tokens/grid";
import { Device } from "../../../ui-library/design-tokens/media-queries";

const buttons: CSSObject = {
  marginBottom: grid(-1),

  [Device.DesktopTablet]: {
    marginBottom: grid(-1.5),
  },
};

const button: CSSObject = {
  minWidth: grid(6),
  marginRight: grid(1),
  marginBottom: grid(1),
  [Device.DesktopTablet]: {
    marginRight: grid(1.5),
    marginBottom: grid(1.5),
  },
};

const styles = {
  buttons,
  button,
};

export default styles;
