import * as React from "react";
import { MarketDays } from "../../../../../../../types/coin-market-data";
import { SiteContext } from "../../../../../../site-context/site-context";
import { EventCategory } from "../../../../../../utils/analytics";
import { Device } from "../../../../../ui-library/design-tokens/media-queries";
import ItemPickerTabs from "../../../../../ui-library/item-picker-tabs/item-picker-tabs";

interface TimeRangeTab {
  label: string;
  value: MarketDays;
}

export const marketTimeRangeTabs: TimeRangeTab[] = [
  {
    label: "24h",
    value: "1",
  },
  {
    label: "7d",
    value: "7",
  },
  {
    label: "14d",
    value: "14",
  },
  {
    label: "30d",
    value: "30",
  },
  {
    label: "90d",
    value: "90",
  },
  {
    label: "180d",
    value: "180",
  },
  {
    label: "1y",
    value: "365",
  },
  {
    label: "Max",
    value: "max",
  },
];

interface MarketTimeRangeTabsProps {
  activeTab: MarketDays;
  setActiveTab: (tab: MarketDays) => void;
  eventCategory: EventCategory;
}

const MarketTimeRangeTabs: React.FC<MarketTimeRangeTabsProps> = ({
  activeTab,
  setActiveTab,
  eventCategory,
}) => {
  const { isMobile } = React.useContext(SiteContext);

  return (
    <div
      css={{
        [Device.Mobile]: {
          div: { display: "flex" },
        },
      }}
    >
      <ItemPickerTabs
        itemPickerName="coinMarketChartType"
        activeItemValue={activeTab}
        items={marketTimeRangeTabs}
        isUniWidth={true}
        isScrollable={isMobile}
        onItemClick={(item) => setActiveTab(item as MarketDays)}
        eventCategory={eventCategory}
      />
    </div>
  );
};

export default MarketTimeRangeTabs;
