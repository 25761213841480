import * as React from "react";
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import formatDate from "dateformat";
import { transparentize } from "polished";
import { MarketDays } from "../../../../../../../types/coin-market-data";
import { useCoinMarketData } from "../../../../../../hooks/query-hooks/use-coin-market-data";
import { TooltipContainerStyles } from "../../../../../ui-library/design-tokens/charts";
import { Colors } from "../../../../../ui-library/design-tokens/colors";
import {
  formatBigCurrency,
  formatCurrency,
} from "../../../../../../utils/format-number";
import { CurrencySymbol } from "../../../../../../../types/currency";
import { Fonts } from "../../../../../ui-library/design-tokens/fonts";
import LoadingState from "../../../../../ui-library/loading-state/loading-state";
import { Card } from "../../../../../ui-library/cards/card";

const timeFormat = (startTime: number, endTime: number) => {
  const days = (endTime - startTime) / (1000 * 60 * 60 * 24);
  if (days < 2) {
    return "H:MM";
  }
  if (days < 366) {
    return "dd mmm";
  }
  if (days < 731) {
    return "yyyy mmm";
  }
  return "yyyy";
};

interface MarketChartByTimeRangeProps {
  id: string;
  timeRange: MarketDays;
  symbol?: string;
  type: "price" | "marketCap";
}

const MarketChartByTimeRange: React.FC<MarketChartByTimeRangeProps> = ({
  id,
  timeRange,
  symbol,
  type,
}) => {
  const { data, loading } = useCoinMarketData({ id, days: timeRange });

  if (loading && !data) {
    return (
      <div css={{ position: "relative", height: 400 }}>
        <LoadingState cover={true} />
      </div>
    );
  }

  if (!data) {
    return (
      <Card
        css={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 200,
          backgroundColor: Colors.LightestGrey,
        }}
      >
        Sorry, we currently don't have data for{" "}
        {symbol?.toUpperCase() || "this coin"}.
      </Card>
    );
  }

  const dataKey = type === "price" ? "price" : "marketCap";
  const dataName = type === "price" ? "Price" : "MarketCap";

  const startTime = data[0].date;
  const endTime = data[data.length - 1].date;

  const maxPrice = Math.max(...data.map((d) => d.price));
  const minPrice = Math.min(...data.map((d) => d.price));
  const maxMarketCap = Math.max(...data.map((d) => d.marketCap));
  const minMarketCap = Math.min(...data.map((d) => d.marketCap));
  const priceDistance = maxPrice - minPrice;
  const marketCapDistance = maxMarketCap - minMarketCap;
  const valueDistance = type === "price" ? priceDistance : marketCapDistance;

  const timeFormatter = (date: number) =>
    formatDate(new Date(date), timeFormat(startTime, endTime));

  const priceFormatter = (value: number) =>
    formatCurrency({ value, currency: CurrencySymbol.USD });
  const marketCapFormatter = (value: number) =>
    formatBigCurrency({ value, currency: CurrencySymbol.USD });
  const valueFormatter = type === "price" ? priceFormatter : marketCapFormatter;

  return (
    <div
      css={{
        fontSize: 14,
        fontWeight: Fonts.Weight.SemiBold,
        whiteSpace: "nowrap",
      }}
    >
      <ResponsiveContainer width="100%" height={400}>
        <AreaChart data={data}>
          <defs>
            <linearGradient id="market_gradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor={transparentize(0.5, Colors.Blue)} />
              <stop offset="100%" stopColor={transparentize(1, Colors.Blue)} />
            </linearGradient>
          </defs>
          <XAxis
            dataKey="date"
            type="number"
            scale="time"
            domain={["dataMin", "dataMax"]}
            tickFormatter={timeFormatter}
            tickLine={false}
            axisLine={{ stroke: "#d6d9da" }}
            minTickGap={50}
          />
          <YAxis
            dataKey={dataKey}
            orientation="left"
            domain={[
              (dataMin) => dataMin - valueDistance / 10,
              (dataMax) => dataMax + valueDistance / 10,
            ]}
            hide={true}
          />
          <Tooltip
            cursor={false}
            contentStyle={TooltipContainerStyles}
            labelFormatter={(date) =>
              formatDate(new Date(date), "yyyy mmm dd H:MM")
            }
            formatter={(value, name) => [valueFormatter(value as number), name]}
            itemStyle={{ paddingBottom: 0 }}
          />
          <Area
            dataKey={dataKey}
            fill={`url(#market_gradient)`}
            name={dataName}
            dot={false}
            strokeWidth={2.5}
            stroke={Colors.Blue}
            connectNulls={true}
            animationDuration={500}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default MarketChartByTimeRange;
