import * as React from "react";
import Image from "../../../ui-library/image/image";
import { copyToClipboard } from "../../../../utils/dom/copy-to-clipboard";
import ToastCard, {
  useToast,
} from "../../../ui-library/cards/toast-card/toast-card";
import PillButton from "../../../ui-library/buttons/pill-button";
import { grid } from "../../../ui-library/design-tokens/grid";
import { EventCategory } from "../../../../utils/analytics";
import { SiteContext } from "../../../../site-context/site-context";
import CopyIcon from "./assets/copy-icon.svg";
import styles from "./coin-links.styles";

interface CopyContractAddressProps {
  contractAddress: string;
  eventCategory: EventCategory;
}

const CopyContractAddress: React.FC<CopyContractAddressProps> = ({
  contractAddress,
  eventCategory,
}) => {
  const { isMobile } = React.useContext(SiteContext);

  const { trigger, isTriggered } = useToast();

  const handleCopy = () => {
    copyToClipboard(contractAddress);
    trigger();
  };

  return (
    <PillButton
      name="Contract address"
      size={isMobile ? "small" : "large"}
      target="_blank"
      css={styles.button}
      onClick={handleCopy}
      eventCategory={eventCategory}
    >
      Contract address
      <Image src={CopyIcon} alt="Copy" css={{ marginLeft: grid(1) }} />
      <ToastCard isTriggered={isTriggered}>
        <div css={{ textAlign: "center" }}>Contract address copied</div>
        <div css={{ textAlign: "center" }}>
          {contractAddress.slice(0, 6)}...{contractAddress.slice(-6)}
        </div>
      </ToastCard>
    </PillButton>
  );
};

export default CopyContractAddress;
