import * as React from "react";
import { MarketDays } from "../../../../../../../types/coin-market-data";
import { EventCategory } from "../../../../../../utils/analytics";
import { grid } from "../../../../../ui-library/design-tokens/grid";
import MarginBox from "../../../../../ui-library/margin-box/margin-box";
import TradingViewAnalysis from "../tradingview/tradingview-analysis";
import TradingViewChart from "../tradingview/tradingview-chart";
import ChartEX from "../chartex/chartex";
import CoinMarketChartTabs, {
  MARKET_CHART_TYPES,
} from "./coin-market-chart-tabs";
import MarketChartByTimeRange from "./market-chart-by-time-range";
import MarketTimeRangeTabs from "./market-time-range-tabs";
import styles from "./coin-market-charts.styles";

interface CoinMarketChartProps {
  id: string;
  symbol?: string;
  topTargetSymbol?: string;
  topExchange?: string;
  contractAddress?: string;
}

const CoinMarketChart: React.FC<CoinMarketChartProps> = ({
  id,
  symbol,
  topTargetSymbol,
  topExchange,
  contractAddress,
}) => {
  const [activeTab, setActiveTab] = React.useState<string>(
    MARKET_CHART_TYPES[0]
  );
  const [activeTimeRange, setActiveTimeRange] = React.useState<MarketDays>("1");

  const eventCategory = EventCategory.CoinPage;

  return (
    <div>
      <div css={styles.tabsContainer}>
        <CoinMarketChartTabs
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          isUniswap={topExchange === "uniswap"}
          eventCategory={eventCategory}
        />
        {(activeTab === MARKET_CHART_TYPES[0] ||
          activeTab === MARKET_CHART_TYPES[1]) && (
          <MarketTimeRangeTabs
            activeTab={activeTimeRange}
            setActiveTab={setActiveTimeRange}
            eventCategory={eventCategory}
          />
        )}
      </div>

      {activeTab === MARKET_CHART_TYPES[0] && (
        <MarketChartByTimeRange
          id={id}
          timeRange={activeTimeRange}
          symbol={symbol}
          type="price"
        />
      )}

      {activeTab === MARKET_CHART_TYPES[1] && (
        <MarketChartByTimeRange
          id={id}
          timeRange={activeTimeRange}
          symbol={symbol}
          type="marketCap"
        />
      )}

      {symbol && activeTab === MARKET_CHART_TYPES[2] && (
        <>
          <MarginBox
            margin={{
              desktop: { bottom: grid(3) },
              mobile: { bottom: grid(2) },
            }}
          >
            <TradingViewChart
              symbol={symbol}
              topTargetSymbol={topTargetSymbol}
            />
          </MarginBox>
          <TradingViewAnalysis id={id} symbol={symbol} />
        </>
      )}

      {symbol && activeTab === MARKET_CHART_TYPES[3] && (
        <ChartEX symbol={symbol} contractAddress={contractAddress} />
      )}
    </div>
  );
};

export default CoinMarketChart;
