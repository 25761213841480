import * as React from "react";
import { CoinDetailsMapped } from "../../../../../../types/api-results/coin-details";
import MarginBox from "../../../../ui-library/margin-box/margin-box";
import { grid } from "../../../../ui-library/design-tokens/grid";
import AthAtl from "./ath-atl/ath-atl";
import PriceChangeOfTimeFrame from "./price-change-of-time-frame/price-change-of-time-frame";
import CoinMarketChart from "./coin-market-charts/coin-market-charts";

interface CoinStatsProps {
  data: CoinDetailsMapped;
  topTargetSymbol?: string;
  topExchange?: string;
  loading: boolean;
}

const CoinStats: React.FC<CoinStatsProps> = ({
  data,
  topTargetSymbol,
  topExchange,
}) => {
  const { marketData, symbol, id, contractAddress } = data;

  return (
    <div>
      <MarginBox
        margin={{ desktop: { bottom: grid(3) }, mobile: { bottom: grid(1) } }}
      >
        <PriceChangeOfTimeFrame marketData={marketData} />
      </MarginBox>

      <MarginBox
        margin={{ desktop: { bottom: grid(2) }, mobile: { bottom: grid(1) } }}
      >
        <AthAtl marketData={marketData} />
      </MarginBox>

      <CoinMarketChart
        id={id}
        symbol={symbol}
        topTargetSymbol={topTargetSymbol}
        topExchange={topExchange}
        contractAddress={contractAddress}
      />
    </div>
  );
};

export default CoinStats;
