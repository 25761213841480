import { PageProps } from "gatsby";
import * as React from "react";
import { CoinListItem, CoinomicsQuery_CoinCategory } from "../../graphql-types";
import Layout from "../components/layout/layout";
import { NavTab } from "../components/layout/navigation/navigation";
import Coin from "../components/pages/coin/coin";
import SEO from "../components/seo";

type CoinPageProps = PageProps<
  null,
  {
    coin: CoinListItem;
    categories?: CoinomicsQuery_CoinCategory[] | null;
  }
>;

const CoinPage: React.FC<CoinPageProps> = ({
  pageContext: { coin, categories },
}) => {
  const coinName = coin.name || "Coin";

  return (
    <Layout activeTab={NavTab.Coins}>
      <SEO
        title={`${coinName} price, market stats, charts and exchanges`}
        description={`${coinName} real-time price, market insights, and charts. Find out where can you buy ${coinName}.`}
      />

      <Coin id={coin.id} staticData={coin} categories={categories} />
    </Layout>
  );
};

export default CoinPage;
