import * as React from "react";
import { Border } from "../../../../../ui-library/design-tokens/borders";
import { Colors } from "../../../../../ui-library/design-tokens/colors";
import { grid } from "../../../../../ui-library/design-tokens/grid";
import { Device } from "../../../../../ui-library/design-tokens/media-queries";

interface ChartEXProps {
  contractAddress?: string;
  symbol: string;
}

const ChartEX: React.FC<ChartEXProps> = ({
  contractAddress = "000000",
  symbol,
}) => {
  const contractLast6 = contractAddress.slice(-6).toUpperCase();
  const url = `https://www.chartex.pro/?symbol=UNISWAP%3A${symbol.toUpperCase()}.${contractLast6}&interval=60&theme=Light`;

  return (
    <div
      css={{
        overflow: "hidden",
        border: `2px solid ${Colors.BorderGrey}`,
        borderRadius: Border.RadiusXs,
        [Device.Mobile]: { marginLeft: grid(-2), marginRight: grid(-2) },
      }}
    >
      <div css={{ [Device.Desktop]: { marginRight: -50 } }}>
        <iframe src={url} height="600" css={{ width: "100%" }} />
      </div>
    </div>
  );
};

export default ChartEX;
