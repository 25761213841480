import * as React from "react";
import { grid } from "../../../../../ui-library/design-tokens/grid";
import { Device } from "../../../../../ui-library/design-tokens/media-queries";

interface TradingViewChartProps {
  symbol: string;
  topTargetSymbol?: string;
}

// TODO: Tradingview duplicated names
const TradingViewChart: React.FC<TradingViewChartProps> = ({
  symbol,
  topTargetSymbol,
}) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const pair = `${symbol.toUpperCase()}${
    topTargetSymbol?.toUpperCase() || "USDT"
  }`;

  React.useEffect(() => {
    const scriptLink = document.createElement("script");
    const scriptTag = document.createElement("script");

    let scriptTimer: NodeJS.Timeout;

    scriptLink.src = "https://s3.tradingview.com/tv.js";
    scriptLink.async = false;
    containerRef.current?.appendChild(scriptLink);

    scriptTimer = setInterval(() => {
      // @ts-ignore
      if (window.TradingView) {
        scriptTag.type = "text/javascript";
        scriptTag.text = `
            new TradingView.widget(
              {
                "autosize": true,
                "symbol": "${pair}",
                "interval": "D",
                "timezone": "Etc/UTC",
                "theme": "light",
                "style": "1",
                "locale": "en",
                "toolbar_bg": "#f1f3f6",
                "enable_publishing": false,
                "withdateranges": true,
                "hide_side_toolbar": false,
                "allow_symbol_change": true,
                "referral_id": "5048",
                "container_id": "tradingview_a6333"
              }
            );`;

        containerRef.current?.appendChild(scriptTag);
        clearInterval(scriptTimer);
      }
    }, 100);

    return () => {
      clearInterval(scriptTimer);
    };
  }, [symbol]);

  return (
    <div>
      <div
        id="tradingview_a6333"
        ref={containerRef}
        css={{
          height: grid(60),
          [Device.Mobile]: { marginLeft: grid(-2), marginRight: grid(-2) },
        }}
      ></div>
      <div className="tradingview-widget-copyright">
        <a
          href={`https://www.tradingview.com/symbols/${pair}/`}
          rel="noopener noreferrer"
          target="_blank"
        >
          <span className="blue-text">{pair} Chart</span>
        </a>{" "}
        by TradingView
      </div>
    </div>
  );
};

export default TradingViewChart;
