import * as React from "react";
import sanitizeHtml from "sanitize-html";
import { CoinDetailsMapped } from "../../../../../types/api-results/coin-details";
import SectionCard from "../../../ui-library/cards/section-card/section-card";
import { Fonts } from "../../../ui-library/design-tokens/fonts";
import { grid } from "../../../ui-library/design-tokens/grid";
import Text from "../../../ui-library/text/text";

const cleanDescription = (description: string) => {
  const descriptionWithNewUrl = description
    .replaceAll("https://www.coingecko.com/en/coins", "/coin")
    .replaceAll("https://www.coingecko.com/en/exchanges", "/exchange");

  return sanitizeHtml(descriptionWithNewUrl, {
    allowedTags: ["p", "div", "a", "span", "strong", "b"],
    transformTags: {
      // @ts-ignore
      a: function (_, attribs) {
        const href: string | undefined = attribs.href;

        if (!href || href.includes("coingecko")) {
          return { tagName: "span" };
        }

        if (href.includes("http")) {
          return {
            tagName: "a",
            attribs: {
              href,
              target: "_blank",
              rel: "noopener noreferrer",
            },
          };
        }

        return {
          tagName: "a",
          attribs: {
            href: `${href}/`,
          },
        };
      },
    },
  });
};
interface CoinDescriptionProps {
  data?: CoinDetailsMapped;
}

const CoinDescription: React.FC<CoinDescriptionProps> = ({ data }) => {
  const name = data?.name;
  const description = data?.description?.en;

  if (!name || !description) {
    return null;
  }

  const descriptions = description.split("\r\n\r\n").map(cleanDescription);

  return (
    <SectionCard>
      <Text
        component="h2"
        textSize={18}
        weight={Fonts.Weight.Bold}
        margin={{ bottom: grid(2) }}
      >
        About {name}
      </Text>

      {descriptions.map((p) => (
        <Text
          key={p.slice(0, 30)}
          component="p"
          textSize={14}
          margin={{ bottom: grid(2) }}
          dangerouslySetInnerHTML={{
            __html: p,
          }}
        />
      ))}
    </SectionCard>
  );
};

export default CoinDescription;
