import { BoxShadow } from "./box-shadow";
import { Colors } from "./colors";

export const TooltipContainerStyles = {
  border: 0,
  borderRadius: "8px",
  fontSize: 12,
  color: Colors.Black,
  boxShadow: BoxShadow.Default,
};
