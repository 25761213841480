import * as React from "react";
import { CoinomicsQuery_CoinCategory } from "../../../../../graphql-types";
import { SiteContext } from "../../../../site-context/site-context";
import { EventCategory } from "../../../../utils/analytics";
import { buildCategoryUrl } from "../../../../utils/build-url";
import PillButton from "../../../ui-library/buttons/pill-button";
import SectionCard from "../../../ui-library/cards/section-card/section-card";
import { Fonts } from "../../../ui-library/design-tokens/fonts";
import { grid } from "../../../ui-library/design-tokens/grid";
import Text from "../../../ui-library/text/text";
import styles from "./coin-categories.styles";

interface CoinCategoriesProps {
  categories: CoinomicsQuery_CoinCategory[];
}

const CoinCategories: React.FC<CoinCategoriesProps> = ({ categories }) => {
  const { isMobile } = React.useContext(SiteContext);

  return (
    <SectionCard>
      <Text
        component="h2"
        textSize={18}
        weight={Fonts.Weight.Bold}
        margin={{ bottom: grid(2) }}
      >
        Related categories
      </Text>

      <div css={styles.buttons}>
        {categories.map(({ id, slug, name }) => (
          <PillButton
            key={id}
            to={buildCategoryUrl(slug)}
            name={name}
            size={isMobile ? "small" : "large"}
            target="_blank"
            css={styles.button}
            eventCategory={EventCategory.CoinPage}
          >
            {name}
          </PillButton>
        ))}
      </div>
    </SectionCard>
  );
};

export default CoinCategories;
