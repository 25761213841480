import * as React from "react";
import { EventCategory } from "../../../../../../utils/analytics";
import { grid } from "../../../../../ui-library/design-tokens/grid";
import { Device } from "../../../../../ui-library/design-tokens/media-queries";
import ItemPickerTabs from "../../../../../ui-library/item-picker-tabs/item-picker-tabs";

export const MARKET_CHART_TYPES = [
  "Price",
  "Market Cap",
  "TradingView",
  "ChartEX",
];

interface CoinMarketChartTabsProps {
  activeTab: string;
  setActiveTab: (tab: string) => void;
  isUniswap?: boolean;
  eventCategory: EventCategory;
}

const CoinMarketChartTabs: React.FC<CoinMarketChartTabsProps> = ({
  activeTab,
  setActiveTab,
  isUniswap,
  eventCategory,
}) => {
  const options = isUniswap
    ? MARKET_CHART_TYPES.filter((t) => t !== "TradingView")
    : MARKET_CHART_TYPES.filter((t) => t !== "ChartEX");

  return (
    <div css={{ [Device.TabletMobile]: { marginBottom: grid(1) } }}>
      <ItemPickerTabs
        itemPickerName="coinMarketChartType"
        activeItemValue={activeTab}
        items={options.map((tab) => ({ label: tab, value: tab }))}
        onItemClick={setActiveTab}
        eventCategory={eventCategory}
      />
    </div>
  );
};

export default CoinMarketChartTabs;
