import { transparentize } from "polished";
import { CSSObject } from "@emotion/react";
import { Border } from "../../../../../ui-library/design-tokens/borders";
import { Colors } from "../../../../../ui-library/design-tokens/colors";
import { Fonts } from "../../../../../ui-library/design-tokens/fonts";
import { grid } from "../../../../../ui-library/design-tokens/grid";
import { Device } from "../../../../../ui-library/design-tokens/media-queries";

const row: CSSObject = {
  display: "flex",
  alignItems: "center",

  [Device.Mobile]: {
    marginLeft: grid(-2),
    marginRight: grid(-2),
  },

  "> div": {
    flex: 1,
    textAlign: "center",
  },
};

const header: CSSObject = {
  height: grid(4),
  fontSize: 12,
  fontWeight: Fonts.Weight.SemiBold,
  color: Colors.MidGrey,
  backgroundColor: transparentize(0.5, Colors.LightGrey),

  [Device.DesktopTablet]: {
    fontSize: 14,
    borderRadius: Border.RadiusXs,
  },
};

const body: CSSObject = {
  height: grid(5),
};

const percent: CSSObject = {
  justifyContent: "center",
  fontSize: 12,
  fontWeight: Fonts.Weight.SemiBold,

  [Device.DesktopTablet]: {
    fontSize: 16,
  },

  [Device.MobilePortXs]: {
    fontSize: 10,
  },
};

const styles = {
  row,
  header,
  body,
  percent,
};

export default styles;
