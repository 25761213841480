import * as React from "react";
import { CoinDetailsMapped } from "../../../../../types/api-results/coin-details";
import { EventCategory } from "../../../../utils/analytics";
import TextSkeleton from "../../../ui-library/text/text-skeleton";
import PillButton from "../../../ui-library/buttons/pill-button";
import { SiteContext } from "../../../../site-context/site-context";
import Text from "../../../ui-library/text/text";
import { Colors } from "../../../ui-library/design-tokens/colors";
import CopyContractAddress from "./copy-contract";
import styles from "./coin-links.styles";
import { flattenCoinLinks } from "./format-coin-links";

interface CoinLinkButtonProps {
  url: string;
  name: string;
}

const CoinLinkButton: React.FC<CoinLinkButtonProps> = ({ url, name }) => {
  const { isMobile } = React.useContext(SiteContext);

  return (
    <PillButton
      key={url}
      to={url}
      name={name}
      size={isMobile ? "small" : "large"}
      target="_blank"
      css={styles.button}
      eventCategory={EventCategory.CoinPage}
    >
      {name}
    </PillButton>
  );
};

interface CoinLinksProps {
  data?: CoinDetailsMapped;
  loading: boolean;
}

const CoinLinks: React.FC<CoinLinksProps> = ({ data, loading }) => {
  const { isMobile } = React.useContext(SiteContext);
  const [isExpanded, setExpanded] = React.useState<boolean>(false);

  const links = data ? flattenCoinLinks(data) : undefined;

  if (loading) {
    return (
      <div>
        <div css={styles.label}>Key links</div>
        <TextSkeleton
          width={180}
          margin={{ desktop: { bottom: 16 }, mobile: { bottom: 6 } }}
        />
      </div>
    );
  }

  if (!data || !links) {
    return null;
  }

  const { contractAddress } = data;

  const { topLinks, otherLinks } = links;

  return (
    <div>
      <div css={styles.label}>Key links</div>

      {topLinks.twitter && <CoinLinkButton {...topLinks.twitter} />}
      {topLinks.telegram && <CoinLinkButton {...topLinks.telegram} />}

      {contractAddress && (
        <CopyContractAddress
          contractAddress={contractAddress}
          eventCategory={EventCategory.CoinPage}
        />
      )}

      {topLinks.topExplorer && <CoinLinkButton {...topLinks.topExplorer} />}
      {topLinks.topWebsite && <CoinLinkButton {...topLinks.topWebsite} />}

      {isExpanded &&
        otherLinks.map((link) => <CoinLinkButton key={link.url} {...link} />)}

      {otherLinks.length > 0 && (
        <PillButton
          name={isExpanded ? "Show less" : "..."}
          size={isMobile ? "small" : "large"}
          css={styles.button}
          onClick={() => setExpanded(!isExpanded)}
          eventCategory={EventCategory.CoinPage}
        >
          <Text textColor={Colors.Blue}>
            {isExpanded ? "Show less" : "..."}
          </Text>
        </PillButton>
      )}
    </div>
  );
};

export default CoinLinks;
